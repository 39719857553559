<template>
  <div>
    <b-modal
      v-model="modalCalendarAppointment"
      :title="`Created ${type == 'event' ? 'Appointment' : 'Call'}`"
      size="lg"
      @hidden="hideModal(false)"
      scrollable
    >
      <div>
        <b-row class="py-1">
          <b-col md="6">
            <div class="d-flex">
              <b-form-input
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="account"
                placeholder="Search Account"
                @keyup.enter="searchaccount()"
              ></b-form-input>

              <b-button variant="primary" @click="searchaccount"
                >Search</b-button
              >
            </div>
            <span v-if="errrorsearch" style="color: red; float: left"
              >Account Not Found
            </span>
          </b-col>
        </b-row>

        <b-row v-if="searchstate" class="py-1">
          <b-col md="6">
            <b-form-group label="Client Name:">
              <b-form-input v-model="name_client" disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Advisor:">
              <b-form-input v-model="name_advisor" disabled></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div v-if="searchstate">
          <ValidationObserver ref="form">
            <b-row>
              <b-col md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-form-group label="Title:">
                    <b-form-input
                      v-model="event.title"
                      :state="errors[0] ? false : null"
                    ></b-form-input>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="sellers"
                  rules="required"
                >
                  <b-form-group label="Seller:">
                    <v-select
                      v-model="event.user_id"
                      :options="sellers"
                      :reduce="(val) => val.id"
                      label="user_name"
                      :class="{ 'border-danger': errors[0] }"
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="location"
                  rules="required"
                >
                  <b-form-group label="Location:">
                    <b-form-input
                      v-model="event.location"
                      :state="errors[0] ? false : null"
                    ></b-form-input>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group label="Description:">
                    <b-form-textarea
                      v-model="event.description"
                      placeholder="Enter something..."
                      rows="3"
                      :state="errors[0] ? false : null"
                    ></b-form-textarea>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="currentDate"
                  rules="required"
                >
                  <b-form-group label="Day event:">
                    <kendo-datepicker
                      :min="minDate"
                      :max="maxDate"
                      :value="currentDate"
                      :format="'MM/dd/yyyy'"
                      v-model="event.date"
                      name="date"
                      id="date"
                      :class="
                        errors[0]
                          ? 'w-100 rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                          : 'w-100 rounded bg-transparent k-picker-custom picker-select-date'
                      "
                    ></kendo-datepicker>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="from"
                  rules="required"
                >
                  <b-form-group label="From:">
                    <kendo-timepicker
                      :min="min"
                      :max="max"
                      :value="value"
                      :format="'HH:mm'"
                      v-model="event.from"
                      name="from"
                      id="from"
                      :class="
                        errors[0]
                          ? 'w-100 rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                          : 'w-100 rounded bg-transparent k-picker-custom picker-select-date'
                      "
                    ></kendo-timepicker>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="to"
                  rules="required"
                >
                  <b-form-group label="To:">
                    <kendo-timepicker
                      :min="min"
                      :max="max"
                      :value="value"
                      :format="'HH:mm'"
                      v-model="event.to"
                      name="to"
                      id="to"
                      :class="
                        errors[0] || errorTo
                          ? 'w-100 rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                          : 'w-100 rounded bg-transparent k-picker-custom picker-select-date'
                      "
                    ></kendo-timepicker>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </ValidationObserver>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="success"
            @click="saveEvent()"
            class="float-right"
            :disabled="spinner"
          >
            <feather-icon icon="SaveIcon" size="15" v-if="!spinner" />
            <b-spinner small v-else></b-spinner>
            SAVE
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GlobalService from "@/service/global/index";
import vSelect from "vue-select";
import CalendarService from "@/views/crm/services/calendar";
export default {
  components: {
    vSelect,
  },
  props: {
    type: {
      type: String,
    },
    state: {
      type: String,
    },
  },
  data() {
    return {
      spinner: false,
      modalCalendarAppointment: false,
      errrorsearch: false,
      searchstate: false,
      open: false,
      errorTo: false,
      sellers: [],
      lead_id: { id: "" },
      date: null,
      value: "00:00 AM",
      min: new Date(1950, 0, 1, 0, 0, 0),
      max: new Date(2049, 11, 31, 24, 0, 0),
      minDate: new Date(),
      maxDate: new Date(2050, 9, 1),
      currentDate: new Date(2017, 9, 15, 10, 30),
      event: {
        user_id: null,
        title: "",
        location: "",
        lead_id: "",
        all_day: "",
        date: "",
        from: "",
        to: "",
        description: "",
        created_users: "",
        type: this.type,
        state: this.state,
      },
      form: {
        id: "",
        user_id: null,
      },
      account: "",
      name_client: "",
      name_advisor: "",
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    ...mapActions("CrmEventStore", ["A_SET_EVENT"]),
    async searchaccount() {
      try {
        let response = await CalendarService.searchAccountName({
          account: this.account,
        });

        if (this.isResponseSuccess(response)) {
          if (response.data[0]) {
            this.name_client = response.data[0].name_client;
            this.name_advisor = response.data[0].name_advisor;
            this.event.lead_id = response.data[0].lead_id;
            this.searchstate = true;
            this.errrorsearch = false;
          } else {
            this.errrorsearch = true;
          }
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong. ${response.message}`
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async saveEvent() {
      const validation = await this.$refs.form.validate();
      if (this.event.from >= this.event.to) {
        this.errorTo = true;
      } else {
        this.errorTo = false;
      }
      if (validation && !this.errorTo) {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          const response = await this.A_SET_EVENT(this.event);
          if (this.isResponseSuccess(response)) {
            this.hideModal(false);
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
          } else {
            this.showToast(
              "warning",
              "top-right",
              "Warning!",
              "AlertTriangleIcon",
              `Something went wrong. ${response.message}`
            );
          }
        }
      }
    },
    hideModal(status) {
      this.modalCalendarAppointment = false;
      this.$emit("hide", status);
    },
    async userCreator() {
      let params = {
        roles: this.moduleId == 20 ? [1,2,14,17] : [1,2], // validation for the most important roles of the module
        search_advisor: this.moduleId == 20 ? 15 : 3, // validation for the less important role of the module, in this case is agent, other could be an advisor
        id_module: this.moduleId
      }
      // Put the other modules in the validation that want to show their users : || this.$route.matched[0].meta.module == 25
      const sellersUsa = (this.moduleId == 20) ? await GlobalService.getUserForCalendar(params) : await GlobalService.getUserByUsa()
      this.sellers = sellersUsa.data
      this.modalCalendarAppointment = true;
    },
  },
  mounted() {
    this.event.created_users = this.currentUser.user_id;
  },
  created() {
    this.userCreator();
  },
};
</script>