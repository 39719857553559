<template>
  <b-container fluid>
    <b-row v-if="!isModal" class="mt-1 mb-2">
      <b-col
        cols="12"
        lg="3"
        class="d-flex align-items-center justify-content-center"
      >
        <v-select
          v-model="host"
          :options="sellers"
          :reduce="(val) => val.id"
          @input="emitEventDateChange"
          class="w-100"
          v-if="moduleId == 2 || moduleId == 20 || moduleId == 26"
        />
        <v-select
          v-model="host"
          :options="sellersUsa"
          :reduce="(val) => val.id"
          label="user_name"
          @input="emitEventDateChange"
          class="w-100"
          v-else
        />
      </b-col>
    </b-row>
    <b-row class="no-gutters">
      <l-calendar
        ref="calendar"
        :events="events"
        date-location="date"
        date-location-format="YYYY-MM-DD"
        :list-title-background-color="skin !== 'dark' ? '#f4f4f4' : ''"
        @dateChange="fetchEvents"
      >
        <template #date-header="{ date, fullDay, currentDate }">
          <div
            class="py-50 text-center w-100 border-bottom font-weight-bolder"
            style="padding: 10px 0 !important"
            :class="
              currentDate
                ? `bg-success text-white border-bottom-0 ${
                    skin !== 'dark' ? 'border-c4c4c4' : ''
                  }`
                : ''
            "
          >
            {{ fullDay }}, {{ date }}
          </div>
        </template>

        <template #date="{ events, haveEvents }">
          <div class="w-100" :class="haveEvents ? `text-white` : ''">
            <task-calendar
              style="padding-top: 30px !important"
              v-if="haveEvents"
              :task="events[0]"
              :type="'appointment'"
            />
          </div>
        </template>

        <template
          #date-footer="{
            haveEvents,
            events,
            numberOfEvents,
            fullMonth,
            year,
            day,
          }"
        >
          <span
            v-if="haveEvents"
            class="px-50 pb-50 pt-0 text-right w-100"
            style="padding-bottom: 20px !important"
            :class="haveEvents ? `bg-light-${taskColor[events[0].title]} ` : ''"
          >
            <b-button
              class="rounded mr-50"
              variant="warning"
              style="
                padding: 4px;
                background: #00aaaa !important;
                border-color: #00aaaa !important;
              "
              @click="openModalEditEventShow(events[0])"
            >
              <feather-icon icon="Edit2Icon" size="12" />
            </b-button>
            <b-button
              v-if="numberOfEvents > 1"
              class="rounded"
              variant="warning"
              style="
                padding: 4px;
                background: #ff7a00 !important;
                border-color: #ff7a00 !important;
              "
              @click="openViewMoreEvents(events, fullMonth, year, day)"
            >
              <tabler-icon icon="ListIcon" size="12" />
            </b-button>
          </span>
        </template>
        <template #date-list="{ event }">
          <div class="w-100">
            <task-calendar
              class="cursor-pointer"
              :task="event"
              @click.native="openModalEditEventShow(event)"
              :type="'appointment'"
            />
          </div>
        </template>
      </l-calendar>
    </b-row>
    <b-modal
      :id="isModal ? 'modal-event-edit-modal' : 'modal-event-edit'"
      ok-only
      modal-class="modal-primary"
      class="zindex-4"
      centered
      size="lg"
      title="EDIT"
      hide-footer
      no-close-on-backdrop
    >
      <modal-event-edit
        :only-read="onlyRead"
        :lead="lead"
        :event="selectedEvent"
        @modal-add-service="openModalAddServive"
        @updated="updatedEvent"
      />
    </b-modal>
    <!-- modal Add Service -->
    <modal-add-service
      v-if="showModalAddService"
      :programs-all="programsAll"
      :module="module"
      :sales-client="salesClient"
      :users-services="servicesUsers"
      :current-user="currentUser"
      @changeProgram="openModalProgram"
      @hideModal="showModalAddService = false"
    />
    <view-more-events
      v-if="viewMoreEventsController"
      :events="viewMoreEventsData.events"
      :month="viewMoreEventsData.month"
      :year="viewMoreEventsData.year"
      :day="viewMoreEventsData.day"
      @close="closeViewMoreEvents"
    >
      <template #event-place="{ event }">
        <task-calendar
          v-if="event.id"
          :task="event"
          class="cursor-pointer"
          @click.native="openModalEditEventShow(event)"
          :type="'appointment'"
        />
      </template>
    </view-more-events>
  </b-container>
</template>

<script>
import TaskCalendar from "@/views/crm/views/calendar/components/TaskCalendar.vue";
import CalendarService from "@/views/crm/services/calendar";
import EventService from "@/views/crm/services/event";
import ModalEventEdit from "@/views/crm/views/Lead/lead-event/ModalEventEdit.vue";
import ModalAddService from "@/views/commons/components/lead-programs/components/lead-table/ModalAddService.vue";
import ViewMoreEvents from "@/views/crm/views/calendar/components/ViewMoreEvents.vue";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import vSelect from "vue-select";
import { mapGetters, mapState } from "vuex";
import LCalendar from "@/views/commons/calendar/LCalendar.vue";

import GlobalService from "@/service/global/index";

export default {
  name: "CalendarTest",
  components: {
    ViewMoreEvents,
    TaskCalendar,
    LCalendar,
    ModalEventEdit,
    vSelect,
    ModalAddService,
  },
  props: {
    isModal: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      taskColor: {
        TEL: "primary",
        CN: "primary",
        Personal: "primary",
        Family: "primary",
        ETC: "primary",
      },
      showFilter: false,
      viewMoreEventsController: false,
      events: [],
      host: 0,
      selectedEvent: {},
      viewMoreEventsData: {
        events: [],
        month: "January",
        day: 1,
        year: 2022,
      },
      module: this.moduleId,
      programsAll: [],
      showModalAddService: false,
      onlyRead: false,
      lead: {},
      salesClient: {},
      servicesUsers: [],
      authUser: "",
      sellersUsa: [],
    };
  },
  computed: {
    ...mapState({
      sellers: (state) => state["crm-store"].sellersCrm,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      skin: "appConfig/skin",
      /* G_TEMPLATES: 'CrmTemplateStore/G_TEMPLATES' */
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    pathNameSaleMade() {
      switch (this.moduleId) {
        case 5:
          return "sales-made-debtsolution-lead";
          break;
        case 7:
          return "sales-made-boostcredit-lead";
          break;
        case 6:
          return "sales-made-creditexperts-lead";
          break;
        case 14:
          return "sales-made-bookeeping-lead";
          break;
        case 10:
          return "sales-made-claimdepartment-lead";
          break;
        case 20:
          return "sales-made-connection-lead";
          break;
        case 11:
          return "sales-made-specialists-lead";
        case 26:
          return "sales-made-sale-lead";
      }
    },
  },
  created() {
    if (this.moduleId == 2) {
      this.$store.dispatch("crm-store/getSellers", {
        module: this.moduleId,
        body: {
          roles: "[1,5,2]",
          type: "1",
        },
      });
      this.authUser = this.currentUser;
    } else if (this.moduleId == 20) {
      this.$store.dispatch("crm-store/getSellers", {
        module: 20,
        body: {
          roles: "[1,2,14,15,17]",
          type: "1",
        },
      });
    } else {
      this.getUserUsa();
    }
    this.setDataBlank("selectedEvent");
  },
  methods: {
    async getUserUsa() {
      let params = {
        roles: this.moduleId == 20 ? [1, 2, 14, 17] : [1, 2], // validation for the most important roles of the module
        search_advisor: this.moduleId == 20 ? 15 : 3, // validation for the less important role of the module, in this case is agent, other could be an advisor
        id_module: this.moduleId,
      };
      // Put the other modules in the validation that want to show their users : || this.moduleId == 25
      const sellersUsa =
        this.moduleId == 20
          ? await GlobalService.getUserForCalendar(params)
          : await GlobalService.getUserByUsa();
      this.sellersUsa = sellersUsa.data;
    },
    async openModalAddServive(data) {
      await this.getAllPrograms();
      await this.getSellers();
      this.showModalAddService = true;
      this.$bvModal.hide("modal-event-edit");
      const sales = {
        id: this.lead.id,
        lead_name: this.lead.lead_name,
        event_id: data,
      };
      this.salesClient = sales;
    },
    async getAllPrograms() {
      try {
        const data = await LeadService.getPrograms();
        if (data) {
          this.programsAll = data;
          const sper = this.currentUser.sale_permission;
          const spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSellers() {
      const result = await LeadService.getUsersAllPrograms({
        id: this.module,
      });
      if (result) {
        result.unshift({
          id: 1,
          user_name: "CEO",
        });
      }
      this.servicesUsers = result;
    },
    async openModalProgram(data) {
      if (data) {
        if (data.captured.id && data.program.id && data.seller.id) {
          const responde = await this.showConfirmSwal(
            "Are you sure?",
            "You won't be able to revert this!"
          );
          if (responde.isConfirmed) {
            await this.addPreloader();
            try {
              const result = await LeadService.insertSaleDebt({
                id: this.salesClient.event_id,
                program: data.program.id,
                done: 1,
                module: this.moduleId,
                captured: data.captured.id,
                seller: data.seller.id,
              });
              if (result.status == 200) {
                this.modalAddProgramsState = false;
                this.$router
                  .push({ name: this.pathNameSaleMade })
                  .catch((err) => {
                    console.log(err);
                  });
                this.removePreloader();
              }
            } catch (error) {
              throw error;
            }
          }
        }
      }
    },
    setDataBlank(key) {
      this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`] = {
        ...this[key],
      };
    },
    emitEventDateChange() {
      this.$refs.calendar.emitEventDateChange();
    },
    updatedEvent() {
      this.$bvModal.hide(
        this.isModal ? "modal-event-edit-modal" : "modal-event-edit"
      );
      this.emitEventDateChange();
    },
    openViewMoreEvents(events, month, year, day) {
      this.viewMoreEventsData.events = events;
      this.viewMoreEventsData.month = month;
      this.viewMoreEventsData.year = year;
      this.viewMoreEventsData.day = day;
      this.viewMoreEventsController = true;
    },
    closeViewMoreEvents() {
      this.viewMoreEventsController = false;
    },
    async fetchEvents({ month, year }) {
      try {
        this.addPreloader();
        const monthInfo = this.$moment(`${month}/1/${year}`);
        if (this.isModal) this.host = this.authUser.user_id;

        let response;
        if (this.moduleId == 2) {
          response = await CalendarService.filterMonthNext({
            year: monthInfo.format("YYYY"),
            month: monthInfo.format("MM"),
            host: this.host ? this.host : 0,
          });
        } else if (
          this.moduleId == 20 ||
          this.moduleId == 4 ||
          this.moduleId == 26 ||
          this.moduleId == 11
        ) {
          // Put the other modules in the validation to show their events
          response = await CalendarService.filterMonthNext({
            year: monthInfo.format("YYYY"),
            month: monthInfo.format("MM"),
            host: this.host ? this.host : 0,
            module: this.module,
          });
        } else {
          response = await CalendarService.getAppointmentsProgram({
            year: monthInfo.format("YYYY"),
            month: monthInfo.format("MM"),
            host: this.host ? this.host : 0,
          });
        }

        if (this.isResponseSuccess(response)) {
          this.events = JSON.parse(response.data[0].events_month);
          this.removePreloader();
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong. ${response.message}`
          );
        }
      } catch (error) {
        console.log("Something went wrong fetchEvents", error);
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
    resetData(key) {
      this[key] = {
        ...this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`],
      };
    },
    async openModalEditEventShow(event) {
      try {
        this.lead = Object.assign({}, event);
        const { id } = event;
        const response = await EventService.getEvent({ id });
        this.resetData("selectedEvent");
        if (this.isResponseSuccess(response)) {
          [this.selectedEvent] = response.data;
          this.selectedEvent.user_id = {
            label: this.selectedEvent.seller_name,
            value: this.selectedEvent.seller_id,
          };
          this.$bvModal.show(
            this.isModal ? "modal-event-edit-modal" : "modal-event-edit"
          );
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong. ${response.message}`
          );
        }
      } catch (error) {
        console.log("Something went wrong getEvents", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
  },
};
</script>

<style scoped>
.bg-light-success {
  background-color: rgba(0, 255, 107, 0.07) !important;
}
.bg-light-primary {
  background-color: rgba(0, 144, 231, 0.07) !important;
}
.current-date-class {
  background-color: #00d25b;
  color: white;
}
/*background color #f3f3f3*/
.bg-light-gray {
  background-color: rgba(243, 243, 243, 0.07) !important;
}
.border-c4c4c4 {
  border-color: #c4c4c4 !important;
}
</style>
